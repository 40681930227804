.pagination-buttons .btn-previus{
    position: fixed;
    border-radius: 50%;
    height: 60px;
    margin-left: 10px;
    top: 50%;
}

.pagination-buttons .btn-previus:hover{
    background: transparent !important;
    color: blue !important;
}

.pagination-buttons .btn-next{
    position: fixed;
    border-radius: 50%;
    height: 60px;
    right: 40px;
    top: 50%;
}

.pagination-buttons .btn-next:hover{
    background: transparent !important;
    color: blue !important;
}
