.modalCustom {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
  }
  .modal-mainC {
    position: fixed;
    width: 85%;
    max-width: 650px;
    height: auto;
    max-height: 540px;
    top: 55%;
    width: 60%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }